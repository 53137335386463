import {defineStore} from "pinia";
// import { getMenus } from "@/api/modules/user";
// import { generateRouter } from "@/utils";
import {MeunState} from "../interface";
import {getStorage} from "@/storage";
// import { getShowMenuList } from "@/utils";
import ServicRoutes from "@/router/staticRoutes/ServicRoutes";
import UserRoutes from "@/router/staticRoutes/UserRoutes";
import ChannelRoutes from "@/router/staticRoutes/ChannelRoutes";
import { generateRouter } from "@/utils";
export const useMenuStore = defineStore({
    id: "menu",
    state: (): MeunState => ({
        menusList: []
    }),
    getters: {
        // 菜单权限列表 ==> 左侧菜单栏渲染，需要剔除 isHide == true
        // showMenuListGet: state => getShowMenuList(state.menusList)
    },
    actions: {
        /** 获取侧边栏菜单 */
        async getMenus() {

            // const { data } = await getMenus();
            const client: any = getStorage("client")
            if (client === 'UserRoutes') {
                this.menusList = generateRouter(UserRoutes);
            }
            if (client === 'ServicRoutes') {

                this.menusList =generateRouter(ServicRoutes) ;
            }
            if (client === 'ChannelRoutes') {

                this.menusList =generateRouter(ChannelRoutes) ;
            }
        }
    }
});
