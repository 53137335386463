<template>
    <div>
        <a-form layout="inline">
            <a-form-item>
                <a-input allowClear v-model:value="fromList.orderUserName" placeholder="客户"></a-input>
            </a-form-item>
            <a-form-item>
                <el-date-picker
                        v-model="time"
                        type="daterange"
                        unlink-panels
                        range-separator="~"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        @change="changeTime"
                />
            </a-form-item>
            <a-form-item>
                <a-button @click="handleSearch" >查询</a-button>
                <a-button @click="handleRest">重置</a-button>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>
    import {reactive, toRefs} from "vue";
    import {ElDatePicker} from 'element-plus'

    export default {
        name: "SearchMode",
        setup(props, context) {
            const state = reactive({
                fromList: {
                    orderUserName: '',
                    createTimeStart: '',
                    createTimeEnd: '',
                    pageSize: 10,
                    pageNum: 1,
                    type:1
                },
                time: null,
            })
            const handleRest = () => {
                state.fromList = {
                    orderUserName: '',
                    createTimeStart: '',
                    createTimeEnd: '',
                    pageSize: 10,
                    pageNum: 1,
                    type:1
                }

                context.emit('handleRest', state.fromList)
            }
            const changeTime = (e)=>{
                state.fromList.createTimeStart = e[0]
                state.fromList.createTimeEnd = e[1]
            }
            const handleSearch = () => {
                context.emit('handleRest', state.fromList)
            }
            const handleAdd = () => {
                context.emit('handle-click')
            }

            return {
                ...toRefs(state),
                handleRest,
                handleSearch,

                handleAdd,
                changeTime
            }
        }
    }
</script>

<style scoped lang="scss">
    button {
        width: 100px;
        margin-right: 20px;
    }
</style>
