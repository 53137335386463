
// 校验手机号码




export async function  validatePhone(rule:any,value:any) {
    let three = /^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/i;
    if (value == "") {
        return Promise.reject("请输入电话号码");//返回错误校验提示
    } else if (!three.test(value)) {
        return Promise.reject("电话号码输入错误");
    }
    else {
        return Promise.resolve()//正确校验通过
    }
}

export async function  validatePhoneNum(rule:any,value:any) {
    let reg01 = /^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/i;
    var reg02 = /^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/;
    if (value == "") {
        return Promise.reject("请输入电话号码");//返回错误校验提示
    } else if (!reg01.test(value) &&  !reg02.test(value)) {
        return Promise.reject("请输入正确的号码，手机号136xxxxxx或座机023 - 52xxxxxx");
    }
    else {
        return Promise.resolve()//正确校验通过
    }
}

// 校验邮箱



export async function  validateEmial(rule:any,value:any) {
    let three = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    if (value == "") {
        return Promise.reject("请输入邮箱");//返回错误校验提示
    } else if (!three.test(value)) {
        return Promise.reject("邮箱格式输入错误");
    }
    else {
        return Promise.resolve()//正确校验通过
    }
}

// 校验邮编


export async function  validatePostalCode(rule:any,value:any) {
    let three = /^[1-9]\d{5}$/;
    if (value == "") {
        return Promise.reject("请输入邮编");//返回错误校验提示
    } else if (!three.test(value)) {
        return Promise.reject("邮箱输入错误");
    }
    else {
        return Promise.resolve()//正确校验通过
    }
}

// 校验身份证

export async function  validateCardCode(rule:any,value:any) {
    let three = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (value == "") {
        return Promise.reject("请输入身份证");//返回错误校验提示
    } else if (!three.test(value)) {
        return Promise.reject("身份证号码输入错误");
    }
    else {
        return Promise.resolve()//正确校验通过
    }
}
