//渠道端菜单

import orderMangement from '@/views/channelManagement/orderMangement/orderList.vue'
import toker from '@/views/channelManagement/toker/toker.vue'
import wallet from '@/views/channelManagement/wallet/wallet.vue'
import message from '@/views/message/message.vue'
import userCenter from '@/views/channelManagement/userCenter/userCenter.vue'
import settings from '@/views/channelManagement/settings/settings.vue'

import myOrder from '@/views/userManagement/myOrder/myOrder.vue' //我的订单
import sureOrder from '@/views/userManagement/sureOrder/sureOrder.vue' //我的订单
import payStatus from '@/views/userManagement/payStatus/payStatus.vue' //我的订单
import lookDetail from '@/views/userManagement/myOrder/lookDetail/lookDetail.vue' //我的订单
import evaluate from '@/views/userManagement/myOrder/evaluate/evaluate.vue' //我的订单
import submitInformation from '@/views/userManagement/myOrder/submitInformation/submitInformation.vue' //我的订单
import patentDetails from '@/views/userManagement/myOrder/patentDetails/patentDetails.vue' //我的订单
import patentPay from '@/views/userManagement/myOrder/patentPay/patentPay.vue' //我的订单
import personalInformation from '@/views/personalInformation/personalInformation.vue' //个人信息
import personalAuthentication from '@/views/userManagement/personalAuthentication/personalAuthentication.vue' //个人信息
import demandManagement from '@/views/userManagement/demandManagement/demandManagement.vue' //需求管理
import disclosureMaterials from '@/views/userManagement/disclosureMaterials/disclosureMaterials.vue' //交底资料
import addMaterials from '@/views/userManagement/disclosureMaterials/addMaterials/addMaterials.vue' //交底资料
import annualFeeMonitoring from '@/views/userManagement/annualFeeMonitoring/annualFeeMonitoring.vue' //年费监控
import myWallet from '@/views/userManagement/myWallet/myWallet.vue' //我的钱包

import collectionStore from '@/views/userManagement/myCollection/collectionStore/collectionStore.vue' //我的收藏
import collectProducts from '@/views/userManagement/myCollection/collectProducts/collectProducts.vue' //我的收藏
import InvoicingInformation from '@/views/userManagement/myInVoice/InvoicingInformation/InvoicingInformation.vue' //我的发票
import voiceList from '@/views/userManagement/myInVoice/voiceList/voiceList.vue' //我的发票
import security from '@/views/userManagement/security/security.vue'


import agentManagement from '@/views/serviceManagement/merchantManagement/agentManagement/agentManagement.vue' //代理人管理
import Information from '@/views/serviceManagement/merchantManagement/Information/Information.vue' //企业信息
import storeManagement from '@/views/serviceManagement/merchantManagement/storeManagement/storeManagement.vue' //门店管理
import productList from '@/views/serviceManagement/productManagement/productList/productList.vue' //产品列表
import productEvaluate from '@/views/serviceManagement/productManagement/evaluate/evaluate.vue' //产品列表
import patentRequirementsManagement
    from '@/views/serviceManagement/preSalePatentManagement/patentRequirementsManagement/patentRequirementsManagement.vue' //售前专利管理
import patentMining
    from '@/views/serviceManagement/preSalePatentManagement/patentMining/patentMining.vue' //专利挖掘
import contractList from '@/views/serviceManagement/contractManagement/contractList/contractList.vue'
import addPatent
    from '@/views/serviceManagement/preSalePatentManagement/addPatent/addPatent.vue' //专利挖掘
import orderList from '@/views/serviceManagement/orderManagement/orderList/orderList.vue' //订单列表
import submitInformation1 from '@/views/serviceManagement/orderManagement/submitInformation/submitInformation.vue' //我的订单
import orderDetail from '@/views/serviceManagement/orderManagement/lookDetail/orderDetail.vue' //订单列表
import patentList from '@/views/serviceManagement/patentManagement/patentList/patentList.vue' //订单列表
import patentDetail from '@/views/serviceManagement/patentManagement/patentDetail/patentDetail.vue' //订单列表
import changePassword from '@/views/serviceManagement/systemSettings/changePassword/changePassword.vue'
import helpAndFeedback from '@/views/serviceManagement/systemSettings/helpAndFeedback/helpAndFeedback.vue'
import aboutUs from '@/views/serviceManagement/systemSettings/aboutUs/aboutUs.vue'
import feeMonitoring from '@/views/serviceManagement/systemSettings/annualFeeMonitoring/annualFeeMonitoring.vue'
import account from '@/views/serviceManagement/walletManagement/account/account.vue'
import transactionFlow from '@/views/serviceManagement/walletManagement/transactionFlow/transactionFlow.vue'

const staticRoutes = [
    {
        path: "/layout",
        name: "layout",
        component: () => import("@/components/layout/index.vue"),
        meta: {title: '首页'},
        children: [


            {
                path: '/personalInformation',
                name: '用户个人信息',
                component: personalInformation,
                meta: {
                    icon: 'home-outlined',
                    title: '个人信息',
                    isHide: true
                },
            },
            {
                path: '/orderMangement',
                name: '渠道订单列表',
                component: orderMangement,
                meta: {
                    icon: '',
                    title: '订单列表',
                },
            },
            {
                path: '/toker',
                name: '渠道拓客管理',
                component: toker,
                meta: {
                    icon: '',
                    title: '拓客管理',
                },
            },
            {
                path: '/wallet',
                name: '渠道我的钱包',
                component: wallet,
                meta: {
                    icon: '',
                    title: '我的钱包',
                },
            },
            {
                path: '/message',
                name: '渠道消息中心',
                component: message,
                meta: {
                    icon: '',
                    title: '消息中心',
                },
            },
            {
                path: '/userCenter',
                name: '渠道个人中心',
                component: userCenter,
                meta: {
                    icon: '',
                    title: '个人中心',
                },
            },
            {
                path: '/settings',
                name: '渠道系统设置',
                component: settings,
                meta: {
                    icon: '',
                    title: '系统设置',
                },
            },
            {
                path: '/serviceManagement',
                name: '服务端商家管理',
                // redirect: "/serviceManagement/Information",
                meta: {
                    icon: '',
                    title: '商家管理',
                    isFull: false,
                },
                children: [
                    {
                        path: '/serviceManagement/Information',
                        name: '服务端企业信息',
                        component: Information,

                        meta: {
                            icon: '',
                            title: '企业信息',
                        },
                    },
                    {
                        path: '/serviceManagement/storeManagement',
                        name: '服务端店铺管理',
                        component: storeManagement,

                        meta: {
                            icon: '',
                            title: '店铺管理',
                        },
                    },
                    {
                        path: '/serviceManagement/agentManagement',
                        name: '服务端代理人管理',
                        component: agentManagement,

                        meta: {
                            icon: '',
                            title: '代理人管理',
                        },
                    },
                ],
            },
            {
                path: '/productManagement',
                name: '服务端产品管理',
                redirect: "/productManagement/productList",
                meta: {
                    icon: '',
                    isFull: false,
                    title: '产品管理',
                },
                children: [
                    {
                        path: '/productManagement/productList',
                        name: '服务端产品列表',
                        component: productList,

                        meta: {
                            icon: '',
                            title: '产品列表',

                        },
                    },
                    {
                        path: '/productManagement/productEvaluate',
                        name: '服务端产品评价',
                        component: productEvaluate,

                        meta: {
                            icon: '',
                            title: '产品评价',
                            isHide: true
                        },
                    },
                ],
            },
            {
                path: '/preSalePatentManagement',
                name: '服务端售前专利管理',
                redirect: "/preSalePatentManagement/patentRequirementsManagement",
                meta: {
                    icon: '',
                    isFull: false,
                    title: '售前专利管理',
                },
                children: [
                    {
                        path: '/preSalePatentManagement/patentRequirementsManagement',
                        name: '服务端专利需求',
                        component: patentRequirementsManagement,

                        meta: {
                            icon: '',
                            title: '专利需求',
                        },
                    },
                    {
                        path: '/preSalePatentManagement/patentMining',
                        name: '服务端专利挖掘',
                        component: patentMining,

                        meta: {
                            icon: '',
                            isHide: true,
                            title: '专利挖掘',
                        },
                    },
                    {
                        path: '/preSalePatentManagement/addPatent',
                        name: '服务端专利新增',
                        component: addPatent,

                        meta: {
                            icon: '',
                            isHide: true,
                            title: '专利新增',
                        },
                    },
                ],
            },
            {
                path: '/patentManagement',
                name: '服务端专利管理',
                redirect: "/patentManagement/patentList",
                meta: {
                    icon: '',
                    isFull: false,
                    title: '专利管理',
                },
                children: [
                    {
                        path: '/patentManagement/patentList',
                        name: '服务端专利列表',
                        component: patentList,

                        meta: {
                            icon: '',
                            title: '专利列表',
                        },
                    },
                    {
                        path: '/patentManagement/patentDetail',
                        name: '服务端专利详情',
                        component: patentDetail,

                        meta: {
                            icon: '',
                            title: '专利详情',
                        },
                    },
                ],
            },
            {
                path: '/orderManagement',
                name: '服务端订单管理',
                redirect: "/orderManagement/orderList",
                meta: {
                    icon: '',
                    title: '订单管理',
                    isFull: false,
                },
                children: [
                    {
                        path: '/orderManagement/orderList',
                        name: '服务端订单列表',
                        component: orderList,

                        meta: {
                            icon: '',
                            title: '订单列表',
                        },
                    },
                    {
                        path: '/orderManagement/orderDetail',
                        name: '服务端订单详情',
                        component: orderDetail,

                        meta: {
                            icon: '',
                            title: '订单详情',
                            isHide: true
                        },
                    },
                    {
                        path: '/orderManagement/submitInformation',
                        name: '服务端提交资料',
                        component: submitInformation1,

                        meta: {
                            icon: '',
                            title: '提交资料',
                            isHide: true
                        },
                    },
                ],
            },
            {
                path: '/contractManagement',
                name: '服务端合同管理',
                redirect: "/contractManagement/contractList",
                meta: {
                    icon: '',
                    title: '合同管理',
                    isFull: false,
                },
                children: [
                    {
                        path: '/contractManagement/contractList',
                        name: '服务端合同列表',
                        component: contractList,

                        meta: {
                            icon: '',
                            title: '合同列表',
                        },
                    },
                ],
            },
            {
                path: '/systemSettings',
                name: '服务端系统设置',
                redirect: "/systemSettings/changePassword",
                meta: {
                    icon: '',
                    title: '系统设置',
                    isFull: false,
                },
                children: [
                    {
                        path: '/systemSettings/changePassword',
                        name: '服务端修改密码',
                        component: changePassword,

                        meta: {
                            icon: '',
                            title: '修改密码',
                        },
                    },
                    {
                        path: '/systemSettings/helpAndFeedback',
                        name: '服务端帮助与反馈',
                        component: helpAndFeedback,

                        meta: {
                            icon: '',
                            title: '帮助与反馈',
                        },
                    },
                    {
                        path: '/systemSettings/aboutUs',
                        name: '服务端关于我们',
                        component: aboutUs,

                        meta: {
                            icon: '',
                            title: '关于我们',
                            isHide: true
                        },
                    },
                    {
                        path: '/systemSettings/feeMonitoring',
                        name: '服务端年费监控',
                        component: feeMonitoring,

                        meta: {
                            icon: '',
                            title: '年费监控',
                        },
                    },
                ],

            },
            {
                path: '/walletManagement',
                name: '服务端钱包管理',
                isFull: false,
                meta: {
                    icon: '',
                    title: '钱包管理',
                },
                children: [
                    {
                        path: '/account',
                        name: '服务端账户管理',
                        component: account,
                        meta: {
                            icon: '',
                            title: '账户管理',
                        },
                    },
                    {
                        path: '/transactionFlow',
                        name: '服务端交易流水',
                        component: transactionFlow,
                        meta: {
                            icon: '',
                            title: '交易流水',
                        },
                    },
                ],
            },
            {
                path: '/myOrder',
                name: '用户我的订单',
                component: myOrder,
                meta: {
                    icon: 'home-outlined',
                    title: '我的订单',
                },
            },
            {
                path: '/demandManagement',
                name: '用户需求管理',
                component: demandManagement,
                meta: {
                    icon: 'home-outlined',
                    title: '需求管理',
                },
            },
            {
                path: '/sureOrder',
                name: '我的订单确认',
                component: sureOrder,

                meta: {
                    icon: '',
                    title: '订单确认',
                    isHide: true
                },
            },
            {
                path: '/payStatus',
                name: '我的订单支付',
                component: payStatus,

                meta: {
                    icon: '',
                    title: '支付状态',
                    isHide: true
                },
            },
            {
                path: '/lookDetail',
                name: '我的订单查看详情',
                component: lookDetail,

                meta: {
                    icon: '',
                    title: '查看详情',
                    isHide: true
                },
            },
            {
                path: '/submitInformation',
                name: '我的订单提交信息确认单',
                component: submitInformation,

                meta: {
                    icon: '',
                    title: '提交信息确认单',
                    isHide: true
                },
            },
            {
                path: '/evaluate',
                name: '我的订单评论',
                component: evaluate,

                meta: {
                    icon: '',
                    title: '评论',
                    isHide: true
                },
            },
            {
                path: '/patentDetails',
                name: '我的订单专利详情',
                component: patentDetails,

                meta: {
                    icon: '',
                    title: '专利详情',
                    isHide: true
                },
            },
            {
                path: '/patentPay',
                name: '我的订单专利支付详情',
                component: patentPay,

                meta: {
                    icon: '',
                    title: '专利支付详情',
                    isHide: true
                },
            },
            {
                path: '/personalInformation',
                name: '用户个人信息',
                component: personalInformation,
                meta: {
                    icon: 'home-outlined',
                    title: '个人信息',
                    isHide: true
                },
            },
            {
                path: '/personalAuthentication',
                name: '用户认证',
                component: personalAuthentication,
                meta: {
                    icon: 'home-outlined',
                    title: '认证信息',
                },
            },
            {
                path: '/disclosureMaterials',
                name: '用户交底资料',
                component: disclosureMaterials,
                meta: {
                    icon: 'home-outlined',
                    title: '交底资料',
                },
            },
            {
                path: '/addMaterials',
                name: '添加用户交底资料',
                component: addMaterials,

                meta: {
                    icon: '',
                    title: '交底资料',
                    isHide: true
                },
            },
            {
                path: '/annualFeeMonitoring',
                name: '用户年费监控',
                component: annualFeeMonitoring,
                meta: {
                    icon: 'home-outlined',
                    title: '年费监控',
                },
            },
            {
                path: '/myWallet',
                name: '用户我的钱包',
                component: myWallet,
                meta: {
                    icon: 'home-outlined',
                    title: '我的钱包',
                },
            },
            {
                path: '/myCollection',
                name: '用户我的收藏',
                meta: {
                    icon: 'home-outlined',
                    title: '我的收藏',
                },
                children: [
                    {
                        path: '/collectionStore',
                        name: '用户店铺收藏',
                        component: collectionStore,
                        meta: {
                            icon: '',
                            title: '店铺收藏',
                        },
                    },
                    {
                        path: '/collectProducts',
                        name: '用户服务收藏',
                        component: collectProducts,
                        meta: {
                            icon: '',
                            title: '服务收藏',
                        },
                    },
                ],
            },
            {
                path: '/myInVoice',
                name: '用户我的发票',
                meta: {
                    icon: 'home-outlined',
                    title: '我的发票',
                },
                children: [
                    {
                        path: '/voiceList',
                        name: '用户我的发票管理',
                        component: voiceList,
                        meta: {
                            icon: '',
                            title: '发票列表',
                        },
                    },
                    {
                        path: '/InvoicingInformation',
                        name: '用户发票信息',
                        component: InvoicingInformation,
                        meta: {
                            icon: '',
                            title: '发票信息',
                        },
                    },
                ],
            },
            {
                path: '/security',
                name: '用户账户安全',
                component: security,
                meta: {
                    icon: 'home-outlined',
                    title: '账户安全',
                },
            },
        ]
    }
]


export default staticRoutes
