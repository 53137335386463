//渠道端菜单

// import orderMangement from '@/views/channelManagement/orderMangement/orderList.vue'
// import toker from '@/views/channelManagement/toker/toker.vue'
// import wallet from '@/views/channelManagement/wallet/wallet.vue'
// import message from '@/views/channelManagement/message/message.vue'
// import userCenter from '@/views/channelManagement/userCenter/userCenter.vue'
// import settings from '@/views/channelManagement/settings/settings.vue'


let orderMangement = 'channelManagement/orderMangement/orderList'
let toker = 'channelManagement/toker/toker'
let wallet = 'channelManagement/wallet/wallet'
let message = 'message/message'
let userCenter = 'channelManagement/userCenter/userCenter'
let settings = 'channelManagement/settings/settings'
let personalInformation = 'personalInformation/personalInformation' //个人信息
const ChannelRoutes = [
    {
        path: '/personalInformation',
        name: '用户个人信息',
        component: personalInformation,
        meta: {
            icon: 'home-outlined',
            title: '个人信息',
            isHide:true
        },
    },
    {
        path: '/orderMangement',
        name: '渠道订单列表',
        component: orderMangement,
        meta: {
            icon: 'icon-dingdan',
            title: '订单列表',
        },
    },
    {
        path: '/toker',
        name: '渠道拓客管理',
        component: toker,
        meta: {
            icon: 'icon-a-zu13812',
            title: '拓客管理',
        },
    },
    {
        path: '/wallet',
        name: '渠道我的钱包',
        component: wallet,
        meta: {
            icon: 'icon-a-zu13815',
            title: '我的钱包',
        },
    },
    {
        path: '/message',
        name: '渠道消息中心',
        component: message,
        meta: {
            icon: 'icon-a-zu13817',
            title: '消息中心',
        },
    },
    {
        path: '/userCenter',
        name: '渠道个人中心',
        component: userCenter,
        meta: {
            icon: 'icon-a-zu13811',
            title: '企业中心',
        },
    },
    {
        path: '/settings',
        name: '渠道系统设置',
        component: settings,
        meta: {
            icon: 'icon-shezhi',
            title: '系统设置',
        },
    }
]

export default ChannelRoutes
