import {createRouter, createWebHashHistory, RouteRecordRaw,createWebHistory} from "vue-router";
// @ts-ignore
import NProgress from "./nprogress"
import defaultRoutes from './defaultRoutes'
import UserRoutes from './staticRoutes/UserRoutes'
import ChannelRoutes from './staticRoutes/ChannelRoutes'
import ServicRoutes from './staticRoutes/ServicRoutes'
import staticRoutes from './staticRoutes/staticRoutes'
import {getStorage, clearStorage, setStorage} from '@/storage'
import {useMenuStore} from "@/store/modules/menu";

NProgress.configure({
    easing: "ease", // 动画方式
    speed: 500, // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3 // 初始化时的最小百分比
})
const routes: Array<RouteRecordRaw> = [...defaultRoutes,...staticRoutes]


const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    setStorage('from',from.fullPath)
    NProgress.start()
    let isLogin = to.meta.isLogin
    const token: any = getStorage("token")
    const menuStore = useMenuStore();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.pageYOffset = 0;
    if (isLogin === false) {
        next()
    } else {
        if (token) {
            // if (!menuStore.menusList.length) {
            //     await menuStore.getMenus();
            //     menuStore.menusList.forEach((item: any) => {
            //         router.addRoute("layout", item as unknown as RouteRecordRaw);
            //     });
            //     let currenRoutes = router.options.routes
            //     if (currenRoutes[currenRoutes.length - 1].path != '/:catchAll(.*)') {
            //         return next(to.fullPath);
            //     }
            // } else {
                next()
            // }
        } else {
            clearStorage()
            next("/login")
        }
    }
})
router.afterEach(() => {
    // 在即将进入新的页面组件前，关闭掉进度条
    NProgress.done()
})

export default router;
