/**
 * @desc: 解析路由
 * @param { Array } routerMap 后台返回的路由表
 * @return 返回
 */

export const generateRouter = (routerMap: any) => {
  return routerMap.map((item: any) => {
    const currentRouter: any = {
      path: item.path,
      name: item.name,
      meta: item.meta,
      redirect: item.redirect
    };
    if (item.component && typeof item.component == "string") {
      currentRouter.component = import(`../views/${item.component}.vue`);
    }
    // 是否存在子路由
    item?.children?.length && (currentRouter.children = generateRouter(item.children));
    return currentRouter;
  });
};

/**
 * @description 使用递归过滤出需要渲染在左侧菜单的列表 (需剔除 isHide == true 的菜单)
 * @param {Array} menuList 菜单列表
 * @returns {Array}
 * */
export function getShowMenuList(menuList: any) {
  let newMenuList: any = JSON.parse(JSON.stringify(menuList));
  return newMenuList.filter((item:any) => {
    item.children?.length && (item.children = getShowMenuList(item.children));
    return !item.meta?.isHide;
  });
}




// @ts-ignore
export function getFindName(id,data){
  for (let i = 0;i<data.length;i++){
    const item = data[i]
    if(item.id == id){
      return [...findName(item),item.name]
    }else if(item.children && item.children.length>0){
      // @ts-ignore
      const  result = getFindName(id,item.children);
      if(result){
        return [...findName(item),...result]
      }
    }
  }
}

// @ts-ignore
function findName(item) {
if(!item){
  return []
}else{
  return [...findName(item.parent),item.name]
}
}


export const objToSearchParams = (obj: Record<string, any>) => {
  return Object.entries(obj)
      .reduce(
          (searchParams, [name, value]) => (searchParams.append(name, value), searchParams),
          new URLSearchParams(),
      )
      .toString();
};

export const  formatTime = (time:any) =>{
  let formTime = ''
  var sTime = new Date(time); //开始时间
  var eTime = new Date(); //结束时间
  let diffTime = eTime.getTime() - sTime.getTime()
  if (diffTime < 60000) {
    formTime = ''
  }
  if (diffTime > 60001 && diffTime < 86400000) {

    let h = sTime.getHours()
    let m = sTime.getMinutes()
    formTime = (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m)
  }

  if (diffTime > 86400001 && diffTime < 172800000) {
    let h = sTime.getHours()
    let m = sTime.getMinutes()
    formTime = '昨天' + (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m)
  }
  if (diffTime > 172800000 && diffTime < 604800000) {
    let day = new Array("日", "一", "二", "三", "四", "五", "六")
    let h = sTime.getHours()
    let m = sTime.getMinutes()
    let week = sTime.getDay();
    formTime = '星期' + day[week] + (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m)
  }
  if (diffTime > 604800001) {
    formTime = time
  }
  return formTime
}
export const StringTw = () =>{
  let str='';
  let arr=[];
  for (let i=1;i<=32;i++){
    let list=['a','b','c','d','e','f','g',
      '1','2','3','4','5','6','7','8','9','0'];

    let num=Math.round(Math.random()*16);
    let add=list[num];
    arr.push(add);
  }
  str=arr.toString();
  str=str.replace(/,/g,"");
  return str;
}

// 判断是否为图片

export const getFileType =(fileName:any) => {
  // 后缀获取
  let suffix = '';
  // 获取类型结果
  let result :any = '';
  try {
    const flieArr = fileName.split('.');
    suffix = flieArr[flieArr.length - 1];
  } catch (err) {
    suffix = '';
  }
  // fileName无后缀返回 false
  if (!suffix) { return false; }
  suffix = suffix.toLocaleLowerCase();
  // 图片格式
  const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
  // 进行图片匹配
  result = imglist.find(item => item === suffix);
  if (result) {
    return 'image';
  }
  // 匹配txt
  const txtlist = ['txt'];
  result = txtlist.find(item => item === suffix);
  if (result) {
    return 'txt';
  }
  // 匹配 excel
  const excelist = ['xls', 'xlsx'];
  result = excelist.find(item => item === suffix);
  if (result) {
    return 'excel';
  }
  // 匹配 word
  const wordlist = ['doc', 'docx'];
  result = wordlist.find(item => item === suffix);
  if (result) {
    return 'word';
  }
  // 匹配 pdf
  const pdflist = ['pdf'];
  result = pdflist.find(item => item === suffix);
  if (result) {
    return 'pdf';
  }
  // 匹配 ppt
  const pptlist = ['ppt', 'pptx'];
  result = pptlist.find(item => item === suffix);
  if (result) {
    return 'ppt';
  }
  // 匹配 视频
  const videolist = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'];
  result = videolist.find(item => item === suffix);
  if (result) {
    return 'video';
  }
  // 匹配 音频
  const radiolist = ['mp3', 'wav', 'wmv'];
  result = radiolist.find(item => item === suffix);
  if (result) {
    return 'radio';
  }
  // 其他 文件类型
  return 'other';
}


