

import {axiosHttp} from "../ajaxData"

// 获取会话列表
export function myChatSessionPageList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/chat/chatSession/myChatSessionPageList",
        data:params
    })
}

// 新建会话聊天

export function getChatSession(params:any) {
    return axiosHttp({
        method: "get",
        url: `/chat/chatSession/getChatSession/${params}`,
    })
}
// 获取历史记录




export function listMessagePage(params:any) {
    return axiosHttp({
        method: "get",
        url: `/chat/chatMessage/listMessagePage/${params.chatSessionId}`,
        data:params
    })
}



export function getChatSessionBySessionId(params:any) {
    return axiosHttp({
        method: "get",
        url: `/chat/chatSession/getChatSessionBySessionId/${params}`
    })
}

// 获取客服列表


export function customerServiceList(params:any) {
    return axiosHttp({
        method: "get",
        url: `/system/user/customerServiceList`
    })
}
