import {RouteRecordRaw} from "vue-router";

const defaultRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () =>
            import('@/views/HomeView.vue'),
        name: 'homeView',
        meta: {title: '首页', isLogin: false}
    },
    {
        path: '/login',
        component: () =>
            import('@/views/login/login.vue'),
        name: 'login',
        meta: {title: '登录', isLogin: false}
    },
    {
        path: '/PatentServices',
        component: () =>
            import('@/views/PatentServices/PatentServices.vue'),
        name: 'PatentServices',
        meta: {title: '专利服务', isLogin: false}
    },
    {
        path: '/enterpriseCertification',
        component: () =>
            import('@/views/enterpriseCertification/enterpriseCertification.vue'),
        name: 'enterpriseCertification',
        meta: {title: '企业认证'}
    },
    {
        path: '/productDetails',
        component: () =>
            import('@/views/productDetails/productDetails.vue'),
        name: 'productDetails',
        meta: {title: '专利详情'}
    },
    {
        path: '/purchaseServices',
        component: () =>
            import('@/views/dataUpload/dataUpload.vue'),
        name: 'purchaseServices',
        meta: {title: '购买服务'}
    },
    {
        path: '/merchantDetails',
        component: () =>
            import('@/views/merchantDetails/merchantDetails.vue'),
        name: 'merchantDetails',
        meta: {title: '专利列表'}
    },
    {
        path: '/InstantMessaging',
        component: () =>
            import('@/views/InstantMessaging/InstantMessaging.vue'),
        name: 'InstantMessaging',
        meta: {title: '即时通讯'}
    },
    {
        path: '/enterpriseContent',
        component: () =>
            import('@/views/enterpriseContent/enterpriseContent.vue'),
        name: 'enterpriseContent',
        meta: {title: '企业内容简介'}
    },
    {
        path: "/layout",
        name: "layout",
        component: () => import("@/components/layout/index.vue"),
        // redirect: "/myOrder",
        meta: {title: '首页'},
        children: []
    },
    {
        path: '/:pathMatch(.*)',
        component: () =>
            import('@/views/404.vue'),
        name: 'notFound',
        meta: {title: '404'}
    }
]

export default defaultRoutes
