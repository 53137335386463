import {axiosHttp} from "../ajaxData"

// 帐号密码登录

interface loginInterFace {
    loginModel: string
    userName?: string
    password?: string
    userType?: string
    code?:string
}

// 登录
export function login(params: loginInterFace) {
    return axiosHttp({
        method: "post",
        url: "/auth/login",
        data: params,
    })
}

//注册
export function register(params: loginInterFace) {
    return axiosHttp({
        method: "post",
        url: "/auth/register",
        data: params,
    })
}

// 短信登录



export function smsLogin(params: loginInterFace) {
    return axiosHttp({
        method: "post",
        url: "/auth/smsLogin",
        data: params,
    })
}

// 新登录方式
export function checkAndGetLoginCode(params: any) {
    return axiosHttp({
        method: "post",
        url: "/auth/checkAndGetLoginCode",
        data: params,
    })
}
//登录码登录

export function codeLogin(params: any) {
    return axiosHttp({
        method: "post",
        url: "/auth/codeLogin",
        data: params,
    })
}

// 获取微信二维码


export function wechatLoginQrCode(params: any) {
    return axiosHttp({
        method: "get",
        url: "/auth/wechatLogin",
        data: params,
    })
}

// 开启轮询


export function getWechatLoginResultWithState(params: any) {
    return axiosHttp({
        method: "get",
        url: "/auth/wechatCheckAndGetLoginCode",
        data: params,
    })
}

// 绑定手机号


export function weChatRegistryBind(params: any) {
    return axiosHttp({
        method: "post",
        url: "/auth/weChatRegistryBind",
        data: params,
    })
}

// 获取协议

export function platformAgreement(params: any) {
    return axiosHttp({
        method: "get",
        url: "/system/platformAgreement/getByType",
        data: params,
    })
}
