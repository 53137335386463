import {axiosHttp} from "../ajaxData"


// 订单分页列表



export function orderPageList(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/order/pageList/${params.type}`,
        data: params,
    })
}


// 合同分页列表



export function filesList(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/order/filesList`,
        data: params,
    })
}

// 合同详情


export function getFilesByOrderId(params:any) {
    return axiosHttp({
        method: "get",
        url: `/order/order/getFilesByOrderId/${params}`
    })
}

// 购买服务 提交申请人


export function applierAddlist(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/applier/addList`,
        data: params,
    })
}

// 添加技术交底


export function disclosureMaterialsAdd(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/disclosureMaterials/add`,
        data: params,
    })
}

// 修改交底资料
export function disclosureMaterialsEdit(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/disclosureMaterials/edit`,
        data: params,
    })
}

// 删除交底资料




export function disclosureMaterialsRemove(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/disclosureMaterials/remove/${params}`
    })
}

// 交底资料列表


export function disclosureMaterialspageList(params:any) {
    return axiosHttp({
        method: "get",
        url: `/order/disclosureMaterials/pageList`,
        data: params,
    })
}

// 订单获取交底资料


export function listByOrderId(params:any) {
    return axiosHttp({
        method: "get",
        url: `/order/disclosureMaterials/listByOrderId/${params}`,
    })
}


// 获取交底资料详情



export function disclosureMaterialsqueryById(params:any) {
    return axiosHttp({
        method: "get",
        url: `/order/disclosureMaterials/queryById/${params}`
    })
}

// 交底资料列表



export function disclosureMaterialsqueryList(params:any) {
    return axiosHttp({
        method: "get",
        url: `/order/disclosureMaterials/queryList`,
        data:params
    })
}

// 提交有技术交底



export function orderMaterialsSave(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/orderMaterials/save`,
        data:params
    })
}
// 提交无交底资料
export function orderNoMaterialsDescSave(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/orderNoMaterialsDesc/save`,
        data:params
    })
}

// 提交费减材料



export function feeReductionSave(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/feeReduction/save`,
        data:params
    })
}

// 获取权力人列表


export function applierlistByOrderId(params:any) {
    return axiosHttp({
        method: "get",
        url: `/order/applier/listByOrderId/${params}`,

    })
}

// 产看专利详情



export function queryByIdOrder(params:any) {
    return axiosHttp({
        method: "get",
        url: `/order/order/queryById/${params.id}`,
        data:params

    })
}

// 专利需求列表




export function preOrderPageList(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/order/preOrderPageList/${params.type}`,
        data:params

    })
}

// 专利挖掘列表




export function patentInitList(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patentInit/list`,
        data:params

    })
}

// 专利挖掘添加
export function patentInitAdd(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patentInit/add`,
        data:params

    })
}

// 专利删除


export function patentInitremove(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patentInit/remove/${params}`,


    })
}

// 服务端提交专利


export function patentInitPublish(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patentInit/publish`,
        data:params

    })
}


// 服务端专利分页



export function patentPageList(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patent/pageList`,
        data:params

    })
}

// 用户专利挖掘

export function patentInitcustomEdit(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patentInit/customEdit`,
        data:params

    })
}

// 用户端提交专利


export function patentInitapply(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patent/apply`,
        data:params

    })
}



// 专利列表

export function patentList(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patent/list`,
        data:params

    })
}


// 年费支付
export function orderyearPay(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/yearFeePay/prePay/${params}`,
    })
}

// 跳过支付


export function editStatus(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/yearFeePay/editStatus/${params}`,
    })
}
// 年费状态
export function queryYearFeeResultByPayId(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/yearFeePay/queryYearFeeResultByPayId/${params}`,
    })
}
// 查询支付结果
export function queryYearFeePayResultByPatentFlowId(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/yearFeePay/queryYearFeePayResultByPatentFlowId/${params}`,
    })
}


    // 支付接口
export function orderPay(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/api/wx-pay/prePay/${params}`,
    })
}
// 查询支付状态



export function wxGetBy(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/api/wx-pay/queryOrderPayResultByPayId/${params}`,
    })
}

// 支付信息
export function queryOrderPayResultByOrderId(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/api/wx-pay/queryOrderPayResultByOrderId//${params}`,
    })
}


// 提交用户信息确认单


export function customSubmissions(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patent/customSubmissions`,
        data:params

    })
}

// 提交委托书单个



export function customPowerOfAttUpload(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patent/customPowerOfAttUpload`,
        data:params

    })
}

// 查询专利流转文件



export function listByPatentId(params:any) {
    return axiosHttp({
        method: "get",
        url: `/patent/patentFlowAttachment/listByPatentId/${params}`,
    })
}


// 专利流转详情


export function queryByPatentId(params:any) {
    return axiosHttp({
        method: "get",
        url: `/patent/patentFlow/queryByPatentId/${params}`,
    })
}

// 专利新增流转




export function patentFlowAdd(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patentFlow/add`,
        data:params

    })
}

// 状态流转


export function patentFlowEdit(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patentFlow/edit`,
        data:params

    })
}

// 专利监控


export function patentMonitList(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patentMonit/pageList`,
        data:params

    })
}

// 新增专利监控


export function patentMonitAdd(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patentMonit/addOutsideMonit`,
        data:params

    })
}

// 取消专利监控

export function patentMonitcancelMonit(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patentMonit/cancelMonit/${params.ids[0]}`,
        data:params
    })
}
// 删除专利监控



export function patentMonitDel(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patentMonit/remove/${params.ids[0]}`,
        data:params
    })
}

// 开始监控
export function patentMonitbeginMonit(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patentMonit/beginMonit/${params.ids[0]}`,
        data:params
    })
}
// 修改专利监控



export function patentMonitUpadte(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patentMonit/update`,
        data:params
    })
}

// 监控导入模板

export function download() {
    return axiosHttp({
        method: "post",
        responseType:'blob',
        url: `/patent/patentMonit/importTemplate`,
    })
}


// 添加评价



export function productReviewsAdd(params:any) {
    return axiosHttp({
        method: "post",
        url: `/shop/productReviews/add`,
        data:params
    })
}

// 获取专利详情


export function patentDetail(params:any) {
    return axiosHttp({
        method: "get",
        url: `/patent/patentInit/queryById/${params.id}`,
        data:params
    })
}

// 消息列表

export function stationMessage(params:any) {
    return axiosHttp({
        method: "get",
        url: `/system/stationMessage/list`,
        data:params
    })
}

// 一键已读


export function stationMessageEdit() {
    return axiosHttp({
        method: "post",
        url: `/system/stationMessage/edit`,
    })
}

// 删除消息



export function stationMessageremoveById(params:any) {
    return axiosHttp({
        method: "post",
        url: `/system/stationMessage/removeById`,
        data:params
    })
}

// 获取消息详情


export function stationMessageDetail(params:any) {
    return axiosHttp({
        method: "get",
        url: `/system/stationMessage/${params}`,
    })
}

// 获取列表



export function channelCommissionDetail(params:any) {
    return axiosHttp({
        method: "get",
        url: `/order/channelCommissionDetail/pageList`,
    })
}
