import {getStorage} from "@/storage";
import {message} from "ant-design-vue";

let protocolStr = document.location.protocol;
let ws = 'wss'
if (protocolStr == "https:") {
    ws = 'wss'
} else {
    ws = 'ws'
}


const getWsBaseURL = `${ws}://${window.location.host}${process.env.VUE_APP_BASE_API}/chat-ws/websocket`;

// @ts-ignore


const websocket: socket = {
    websocket: null,
    connectURL: getWsBaseURL,
    // 开启标识
    socket_open: false,
    // 心跳timer
    hearbeat_timer: null,
    // 心跳发送频率
    hearbeat_interval: 2 * 1000,
    // 是否自动重连
    is_reonnect: true,
    // 重连次数
    reconnect_count: 3,
    // 已发起重连次数
    reconnect_current: 1,
    // 重连timer
    reconnect_timer: null,
    // 重连频率
    reconnect_interval: 5 * 1000,
    init: (receiveMessage: Function | null) => {
        if (!('WebSocket' in window)) {
            message.error('浏览器不支持WebSocket')
            return null
        }
        const token = getStorage("token")
        if (!token) {
            return null
        }

        websocket.websocket = new WebSocket(getWsBaseURL, token)
        // 连接成功
        websocket.websocket.onopen = function () {
            websocket.socket_open = true
            websocket.is_reonnect = true
            console.log('链接成功', '--------------')
            // 开启心跳
            console.log(websocket.websocket)
            websocket.heartbeat()
        }
        websocket.websocket.onmessage = (e: any) => {
            console.log('接收到', e)
            if (receiveMessage) {
                receiveMessage(e)
            }
        }
        websocket.websocket.onclose = (e: any) => {
            websocket.socket_open = false
            // 需要重新连接
            if (websocket.is_reonnect) {
                websocket.reconnect_timer = setTimeout(() => {
                    // 超过重连次数
                    if (websocket.reconnect_current > websocket.reconnect_count) {
                        clearTimeout(websocket.reconnect_timer)
                        websocket.is_reonnect = false
                        return
                    }
                    // 记录重连次数
                    websocket.reconnect_current++
                    websocket.reconnect()
                }, websocket.reconnect_interval)
            }
        }

        // 连接发生错误
        websocket.websocket.onerror = function () {
        }
    },
    heartbeat: () => {
        websocket.hearbeat_timer && clearInterval(websocket.hearbeat_timer)

        websocket.hearbeat_timer = setInterval(() => {
            websocket.send('heartBeat')
        }, websocket.hearbeat_interval)
    },
    send: (data: string, callback = null) => {
        console.log('发送内容', data)
        // 开启状态直接发送
        if (websocket.websocket.readyState === websocket.websocket.OPEN) {

            websocket.websocket.send(data === 'heartBeat' ? data : JSON.stringify(data))
            // @ts-ignore
            callback && callback()
        } else {
            clearInterval(websocket.hearbeat_timer)
            message.error('socket链接已断开')

        }
    },
    close: () => {
        clearInterval(websocket.hearbeat_timer)
        websocket.is_reonnect = false
        websocket.websocket.close()
        websocket.websocket = null;
    },
    /**
     * 重新连接
     */
    reconnect: () => {
        if (websocket.websocket && !websocket.is_reonnect) {
            websocket.close()
        }
        websocket.init(null)
    },
}
export default websocket;

