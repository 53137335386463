import { defineStore } from "pinia";
import piniaPersistConfig from "../../config/piniaPersist";
import { UserState } from "../interface";
import {clearStorage} from "@/storage";

export const useUserStore = defineStore({
  id: "user",
  state: (): UserState => ({
    token: "",
    userInfo: {},
    orderNo:'',
    patent:{}
  }),
  getters: {},
  actions: {
    /**
     * @desc: 设置用户信息
     * @param { UserState } userInfo
     */
    setUserInfo(userInfo: UserState["userInfo"]) {
      this.userInfo = userInfo;
    },
    setOrderNo(orderNo:UserState['orderNo']){
      this.orderNo = orderNo
    },
    setpatent(patent:UserState['patent']){
      this.patent = patent
    },
    /**
     * @desc: 设置token
     * @param { string } token
     */
    setToken(token: string) {
      this.token = token;
    },
    /** 登出 */
    async logout() {
      this.resetToken();
      clearStorage()
    },
    /** 清空token及用户信息 */
    resetToken() {
      this.token = "";
      this.userInfo = {};
    }
  },
  persist: piniaPersistConfig("user")
});
