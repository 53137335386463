import * as login from './moudle/login'
import * as user from './moudle/user'
import * as common from './moudle/common'
import * as servicShop from './moudle/servicShop'
import * as order from './moudle/order'
import * as chat from './moudle/chat'

export default {
    login,
    user,
    common,
    servicShop,
    order,
    chat
}
