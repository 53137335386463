<template>
    <div>
        <a-form  layout="inline">
            <a-form-item >
                <a-input allowClear  v-model:value="fromList.orderCode" placeholder="订单号"></a-input>
            </a-form-item>
            <a-form-item >
                <a-input allowClear  v-model:value="fromList.orderUserName" placeholder="用户名"></a-input>
            </a-form-item>
            <a-form-item>
                <el-date-picker
                        v-model="time"
                        type="daterange"
                        unlink-panels
                        range-separator="~"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        @change="changeTime"
                />
            </a-form-item>
            <a-form-item>
                <a-button @click="handleSearch">查询</a-button>
                <a-button @click="handleRest">重置</a-button>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>
    import {reactive, toRefs} from "vue";

    export default {
        name: "SearchMode",
        setup(props, context) {
            const state = reactive({
                fromList: {
                    orderCode:'',
                    orderUserName:'',
                    orderTimeStart:'',
                    orderTimeEnd:'',
                    pageSize: 10,
                    pageNum: 1,
                    type:1
                },
                time: null,

            })
            const handleRest = () => {
                state.fromList = {
                    orderCode:'',
                    orderUserName:'',
                    orderTimeStart:'',
                    orderTimeEnd:'',
                    pageSize: 10,
                    pageNum: 1,
                    type:1
                }

                context.emit('handleRest', state.fromList)
            }
            const handleSearch = () => {
                state.time = null
                context.emit('handleRest', state.fromList)
            }
            const changeTime = (e) =>{
                state.fromList.orderTimeStart = e[0]
                state.fromList.orderTimeEnd = e[1]

            }
            return {
                ...toRefs(state),
                handleRest,
                handleSearch,
                changeTime
            }
        }
    }
</script>

<style scoped lang="scss">
button{
    width: 100px;
    margin-right: 20px;
}
</style>
