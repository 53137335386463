<template>
    <div :style="{ zoom: scaleva }">
        <a-config-provider :locale="zhCN">
            <router-view/>
        </a-config-provider>
        <a-back-top/>
    </div>
</template>

<script setup>

    import {notification} from 'ant-design-vue';
    import {RouterLink, RouterView, useRoute, useRouter} from 'vue-router'
    import zhCN from "ant-design-vue/es/locale/zh_CN";
    import {computed, watch, ref, h} from "vue";
    //ant-design-vue自带，只需要引入
    import {useWebsocketStore} from '@/store/modules/mangerSocket'
    import {getStorage} from "@/storage";
    import {InfoCircleOutlined} from '@ant-design/icons-vue'

    const router = useRouter()
    const route = useRoute()
    const websocket = useWebsocketStore();


    import useCurrentInstance from "@/hooks/useCurrentInstance";

    const {proxy} = useCurrentInstance()
    // import { onMounted, ref } from '@vue/runtime-core'
    //
    // const scaleva = ref()
    // const bodyScale = () => {
    //   const devicewidth = document.documentElement.clientWidth// 获取当前分辨率下的可是区域宽度
    //   const scale = devicewidth / 1920 // 分母——设计稿的尺寸
    //   scaleva.value = scale
    // }
    // onMounted(() => {
    //   bodyScale()
    //   window.onresize = () => {
    //     return (() => {
    //       bodyScale()
    //     })()
    //   }
    // })


    window.addEventListener("beforeunload", () => {
        sessionStorage.setItem("store", 'isR')

    })
    if (sessionStorage.getItem("store")) {
        let store = sessionStorage.getItem("store")

        if (store === 'isR') {
            websocket.initExhibitSocket()
        }
        // 可在赋值后删除储存库中的值
        window.sessionStorage.removeItem("store")
    }
    const message = computed(() => {
        return websocket.message
    })

    watch(message, (newValue, oldValue) => {
        console.log("===================>监听到了", newValue)
        if (newValue) {
            websocket.AcceptMessage(newValue)
            if (route.path !== '/InstantMessaging') {
                if (newValue.messageType == 1 && newValue.fileType == null) {
                    let msg = ''
                    if (newValue.fileType == null) {
                        msg = newValue.message
                    } else {
                        msg = JSON.parse(newValue.message).fileName
                    }
                    notification.open({
                        message: '新消息提示',
                        description: msg,
                        icon: h(InfoCircleOutlined, {style: 'color: #108ee9'}),
                        duration: 3,
                        onClick: () => {
                            proxy.$api.chat.getChatSessionBySessionId(newValue.chatSessionId).then((res) => {
                                websocket.setUserId(res.data.sendUserId)
                                router.push({
                                    path: '/InstantMessaging',
                                })
                            })
                        },
                    });
                }
            }
        }
    }, {immediate: true, deep: true})
</script>

<style lang="scss">
    @import "./assets/css/main.css";
    @import "./assets/css/normalize.css";
    @import "@/assets/css/globalMixin.scss";

    button {
        border-radius: 2px !important;
    }
    .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
        border-start-start-radius: 2px;
    }
    .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
        border-start-end-radius: 2px;
    }
    .ant-table-wrapper .ant-table-container .ant-table-thead > tr > th  {
        background: #C8DCFE;
        color: $font-dd;
        font-weight: bold;
    }
</style>
