<template>
    <div>
        <a-form  layout="inline">
            <a-form-item >
                <a-input allowClear  v-model:value="fromList.productName" placeholder="请输入产品名称"></a-input>
            </a-form-item>
            <a-form-item >
                <a-select
                        v-model:value="fromList.isUsed"
                        style="width: 183px"
                        placeholder="请选择状态"
                >
                    <a-select-option v-for="(item,index) in options" :value="item.value">{{item.label}}</a-select-option>
                </a-select>

            </a-form-item>
            <a-form-item>
                <a-button @click="handleSearch">查询</a-button>
                <a-button @click="handleRest">重置</a-button>
                <a-button @click="handleAdd" type="primary">添加</a-button>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>
    import {reactive, toRefs} from "vue";

    export default {
        name: "SearchMode",
        setup(props, context) {
            const state = reactive({
                fromList: {
                    productName:'',
                    isUsed:null,
                    pageSize: 10,
                    pageNum: 1,
                },

                options: [
                    {
                        value: 0,
                        label: '禁用',
                    },
                    {
                        value: 1,
                        label: '启用',
                    },
                    {
                        value: null,
                        label: '全部',
                    },
                ]
            })
            const handleRest = () => {
                state.fromList = {
                    productName:'',
                    isUsed:null,
                    pageSize: 10,
                    pageNum: 1,
                }

                context.emit('handleRest', state.fromList)
            }
            const handleSearch = () => {
                context.emit('handleRest', state.fromList)
            }
            const handleAdd = () => {
                context.emit('handle-click')
            }

            return {
                ...toRefs(state),
                handleRest,
                handleSearch,
                handleAdd
            }
        }
    }
</script>

<style scoped lang="scss">
button{
    width: 100px;
    margin-right: 20px;
}
</style>
