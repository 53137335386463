<template>
    <div>
        <a-form layout="inline">
            <a-form-item>
                <a-input allowClear v-model:value="fromList.patentName" placeholder="专利名称"></a-input>
            </a-form-item>
            <a-form-item>
                <a-input allowClear v-model:value="fromList.patentCode" placeholder="专利号"></a-input>
            </a-form-item>
            <!-- <a-form-item>
                 <el-date-picker
                         v-model="time"
                         type="daterange"
                         unlink-panels
                         range-separator="~"
                         start-placeholder="开始时间"
                         end-placeholder="结束时间"
                         @change="changeTime"
                 />
             </a-form-item>-->
            <a-form-item>
                <a-button @click="handleSearch">查询</a-button>
                <a-button @click="handleRest">重置</a-button>
                <a-button @click="handleAdd" type="primary">添加</a-button>
                <a-button @click="handleDownload" type="primary">下载模板</a-button>

                <a-upload
                        name="file"
                        :action="uploadeUrl"
                        :headers="header"
                        v-model:file-list="fileList"
                        @change="handleChange"
                >
                    <a-button type="primary">
                        <upload-outlined></upload-outlined>
                        批量导入
                    </a-button>
                </a-upload>

            </a-form-item>
        </a-form>
    </div>
</template>

<script setup>
    import {reactive, ref, toRefs} from "vue";
    import useCurrentInstance from "@/hooks/useCurrentInstance";
    import {getStorage} from "@/storage";
    import {message} from 'ant-design-vue'

    const {proxy} = useCurrentInstance()


    const uploadeUrl = ref(proxy.$api.common.importTemplate())
    const token = getStorage("token")
    const header = ref({
        Authorization: token ? 'Bearer ' + token : null,
    })
    const fromList = ref({

        patentCode: '',
        patentName: '',
        pageSize: 10,
        pageNum: 1,
    })
    const time = ref(null)
    const fileList = ref([])
    const handleRest = () => {
        fromList.value = {
            patentCode: '',
            patentName: '',
            pageSize: 10,
            pageNum: 1,
        }

        $emit('handleRest', fromList.value)
    }
    const handleSearch = () => {
        time.value = null
        $emit('handleRest', fromList.value)
    }
    const handleAdd = () => {
        $emit('handleAdd')
    }
    const changeTime = (e) => {
        fromList.value.orderTimeStart = e[0]
        fromList.value.orderTimeEnd = e[1]

    }

    const handleChange = (info, record) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            handleRest()
            message.success('导入成功')
            fileList.value = []
        }
    };

    const handleDownload = () => {
        proxy.$api.order.download()
    }

    const $emit = defineEmits(['handleRest', 'handleAdd'])
</script>

<style scoped lang="scss">
    button {
        width: 100px;
        margin-right: 20px;
    }
</style>
