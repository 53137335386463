import {defineStore} from 'pinia';
import Socket from '@/utils/socket';
import piniaPersistConfig from "@/config/piniaPersist";
import websocket from "@/utils/websocket";

const path = `ws://${window.location.host}${process.env.VUE_APP_BASE_API}/chat-ws/websocket`;

interface WebSocketModel {
    message: string;
    exhibitWs: Socket | null;
    userId: string | number;
    user: any,
    sessionId: any,
    acceptMessage: any
}

export const useWebsocketStore = defineStore('websocket', {
    state: (): WebSocketModel => ({
        message: '',
        exhibitWs: null,
        userId: '',
        sessionId: '',
        user: {},
        acceptMessage: {}
    }),
    getters: {},
    actions: {
        initExhibitSocket() {
            websocket.init(this.onmessage)
        },
        onmessage(data: any) {
            console.error('收到消息', JSON.parse(data.data))
            this.message = JSON.parse(data.data)
        },
        closeWebSocket() {
            websocket.close()
        },
        sendWebScoket(data: any) {
            websocket.send(data)
        },

        setUserId(id: any) {
            this.userId = id
        },
        setSessionId(id: any) {
            this.sessionId = id
        },
        setUser(val: any) {
            this.user = val
        },
        AcceptMessage(val: any) {
            this.acceptMessage = val
        }
    },
    /*    persist: {
            enabled: true, // 开启数据缓存
            strategies: [
                {
                    key: 'websocket',//存储key值
                    storage: localStorage, // 默认是sessionStorage
                    paths: ['userId'] //指定字段存储数据
                }
            ],
        }*/

    persist: piniaPersistConfig("websocket", ['userId'])
});

