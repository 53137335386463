import { createApp } from "vue";
const app = createApp(App)
import "@/assets/iconfont/iconfont.css"
import "@/assets/css/index.scss";
import App from "./App.vue";
import router from "./router";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css'
import 'element-plus/dist/index.css'
import pinia from '@/store/index'
import Api from './api/index'
import * as Icons from "@ant-design/icons-vue";
// 注册icon组件
Object.keys(Icons).forEach(key => {
    app.component(key, Icons[key as keyof typeof Icons]);
});
import * as ELIcons from "@element-plus/icons-vue"

for (const iconName in ELIcons) {
    app.component(iconName, (ELIcons as any)[iconName])
}
import 'fullpage.js';
// @ts-ignore
import VueFullpage from 'vue-fullpage.js'

import locale from "element-plus/es/locale/lang/zh-cn"
import ElementPlus from "element-plus"

app.use(VueFullpage)
app.use(ElementPlus, { locale })
app.config.globalProperties.$api = Api
app.use(Antd);
app.use(router)
app.use(pinia)
app.mount("#app")

