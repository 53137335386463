// 用户端菜单

// import myOrder from '@/views/userManagement/myOrder/myOrder.vue' //我的订单

// import personalAuthentication from '@/views/userManagement/personalAuthentication/personalAuthentication.vue' //个人信息
// import disclosureMaterials from '@/views/userManagement/disclosureMaterials/disclosureMaterials.vue' //交底资料
// import annualFeeMonitoring from '@/views/userManagement/annualFeeMonitoring/annualFeeMonitoring.vue' //年费监控
// import myWallet from '@/views/userManagement/myWallet/myWallet.vue' //我的钱包
// import myCollection from '@/views/userManagement/myCollection/myCollection.vue' //我的收藏
// import myInVoice from '@/views/userManagement/myInVoice/myInVoice.vue' //我的发票
// import security from '@/views/userManagement/security/security.vue'


// import agentManagement from '@/views/serviceManagement/merchantManagement/agentManagement/agentManagement.vue' //代理人管理
// import Information from '@/views/serviceManagement/merchantManagement/Information/Information.vue' //企业信息
// import storeManagement from '@/views/serviceManagement/merchantManagement/storeManagement/storeManagement.vue' //门店管理
// import productList from '@/views/serviceManagement/productManagement/productList/productList.vue' //产品列表
// import patentRequirementsManagement
//     from '@/views/serviceManagement/preSalePatentManagement/patentRequirementsManagement/patentRequirementsManagement.vue' //售前专利管理
// import orderList from '@/views/serviceManagement/orderManagement/orderList/orderList.vue' //订单列表
// import changePassword from '@/views/serviceManagement/systemSettings/changePassword/changePassword.vue'
// import helpAndFeedback from '@/views/serviceManagement/systemSettings/helpAndFeedback/helpAndFeedback.vue'
// import aboutUs from '@/views/serviceManagement/systemSettings/aboutUs/aboutUs.vue'
// import feeMonitoring from '@/views/serviceManagement/systemSettings/annualFeeMonitoring/annualFeeMonitoring.vue'
// import account from '@/views/serviceManagement/walletManagement/account/account.vue'
// import transactionFlow from '@/views/serviceManagement/walletManagement/transactionFlow/transactionFlow.vue'

let voiceList = "userManagement/myInVoice/voiceList/voiceList";
let InvoicingInformation = "userManagement/myInVoice/InvoicingInformation/InvoicingInformation";


let myOrder = 'userManagement/myOrder/myOrder' //我的订单
let lookDetail = 'userManagement/myOrder/lookDetail/lookDetail' //我的订单
let evaluate = 'userManagement/myOrder/evaluate/evaluate' //我的订单
let submitInformation = 'userManagement/myOrder/submitInformation/submitInformation' //我的订单
let patentDetails = 'userManagement/myOrder/patentDetails/patentDetails' //我的订单
let patentPay = 'userManagement/myOrder/patentPay/patentPay' //我的订单
// let personalInformation = 'userManagement/personalInformation/personalInformation' //个人信息
let demandManagement = 'userManagement/demandManagement/demandManagement' //需求管理
let sureOrder = 'userManagement/sureOrder/sureOrder' //需求管理
let payStatus = 'userManagement/payStatus/payStatus' //我的订单

let personalInformation = 'personalInformation/personalInformation' //个人信息
let personalAuthentication = 'userManagement/personalAuthentication/personalAuthentication' //个人信息
let disclosureMaterials = 'userManagement/disclosureMaterials/disclosureMaterials' //交底资料
let annualFeeMonitoring = 'userManagement/annualFeeMonitoring/annualFeeMonitoring' //年费监控
let myWallet = 'userManagement/myWallet/myWallet' //我的钱包
let myCollection = 'userManagement/myCollection/myCollection' //我的收藏
let myInVoice = 'userManagement/myInVoice/myInVoice' //我的发票
let security = 'userManagement/security/security'
let collectionStore = 'userManagement/myCollection/collectionStore/collectionStore' //我的收藏
let collectProducts = 'userManagement/myCollection/collectProducts/collectProducts' //我的收藏
let message = 'message/message'
const UserRoutes = [
    {
        path: '/myOrder',
        name: '用户我的订单',
        component: myOrder,
        meta: {
            icon: 'Tickets',
            title: '我的订单',
        },
    },
    {
        path: '/demandManagement',
        name: '用户需求管理',
        component: demandManagement,
        meta: {
            icon: 'Collection',
            title: '需求管理',
        },
    },
    {
        path: '/sureOrder',
        name: '我的订单确认',
        component: sureOrder,

        meta: {
            icon: '',
            title: '订单确认',
            isHide: true
        },
    },
    {
        path: '/payStatus',
        name: '我的订单支付',
        component: payStatus,

        meta: {
            icon: '',
            title: '支付状态',
            isHide: true
        },
    },
    {
        path: '/lookDetail',
        name: '我的订单查看详情',
        component: lookDetail,

        meta: {
            icon: '',
            title: '查看详情',
            isHide: true
        },
    },
    {
        path: '/submitInformation',
        name: '我的订单提交信息确认单',
        component: submitInformation,

        meta: {
            icon: '',
            title: '提交信息确认单',
            isHide: true
        },
    },
    {
        path: '/evaluate',
        name: '我的订单评论',
        component: evaluate,

        meta: {
            icon: '',
            title: '评论',
            isHide: true
        },
    },
    {
        path: '/patentDetails',
        name: '我的订单专利详情',
        component: patentDetails,

        meta: {
            icon: '',
            title: '专利详情',
            isHide: true
        },
    },
    {
        path: '/patentPay',
        name: '我的订单专利支付详情',
        component: patentPay,
        meta: {
            icon: '',
            title: '专利支付详情',
            isHide: true
        },
    },
    {
        path: '/personalInformation',
        name: '用户个人信息',
        component: personalInformation,
        meta: {
            icon: 'SetUp',
            title: '个人信息',
            isHide: true
        },
    },

    {
        path: '/personalAuthentication',
        name: '用户认证',
        component: personalAuthentication,
        meta: {
            icon: 'User',
            title: '认证信息',
        },
    },
    {
        path: '/disclosureMaterials',
        name: '用户交底资料',
        component: disclosureMaterials,
        meta: {
            icon: 'Document',
            title: '交底资料',
        },
    },
    {
        path: '/annualFeeMonitoring',
        name: '用户年费监控',
        component: annualFeeMonitoring,
        meta: {
            icon: 'DataBoard',
            title: '年费监控',
        },
    },
    {
        path: '/myWallet',
        name: '用户我的钱包',
        component: myWallet,
        meta: {
            icon: 'Wallet',
            title: '我的钱包',
        },
    }, {
        path: '/myCollection',
        name: '用户我的收藏',
        meta: {
            icon: 'Box',
            title: '我的收藏',
        },
        children: [
            {
                path: '/collectionStore',
                name: '用户店铺收藏',
                component: collectionStore,
                meta: {
                    icon: '',
                    title: '店铺收藏',
                },
            },
            {
                path: '/collectProducts',
                name: '用户服务收藏',
                component: collectProducts,
                meta: {
                    icon: '',
                    title: '服务收藏',
                },
            },
        ],
    },
    {
        path: '/myInVoice',
        name: '用户我的发票',
        meta: {
            icon: 'Money',
            title: '我的发票',
        },
        children: [
            {
                path: '/voiceList',
                name: '用户我的发票管理',
                component: voiceList,
                meta: {
                    icon: '',
                    title: '发票列表',
                },
            },
            {
                path: '/InvoicingInformation',
                name: '用户发票信息',
                component: InvoicingInformation,
                meta: {
                    icon: '',
                    title: '发票信息',
                },
            },
        ],
    },
    {
        path: '/security',
        name: '用户账户安全',
        component: security,
        meta: {
            icon: 'SetUp',
            title: '账户安全',
        },
    },
    {
        path: '/message',
        name: '渠道消息中心',
        component: message,
        meta: {
            icon: 'ChatDotRound',
            title: '消息中心',
        },
    },

]

export default UserRoutes
