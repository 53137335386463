import {axiosHttp} from "../ajaxData"
import {objToSearchParams} from '@/utils'
export function getproductInfoList(params:any) {
    return axiosHttp({
        method: "post",
        url: "/shop/productInfo/enterprise/list",
        data: params,
    })
}


// 用户查询店铺


export function serviceStoreList(params:any) {
    return axiosHttp({
        method: "post",
        url: "/shop/serviceStore/pageList",
        data: params,
    })
}

// 提交用户信息确认单


export function customSubmissions(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patent/service/customSubmissions`,
        data:params

    })
}

// 下一步

export function affirmNext(params:any) {
    return axiosHttp({
        method: "post",
        url: `/patent/patent/service/affirmNext`,
        data:params

    })
}
// 店铺详情



export function getHomeInfoById(params:any) {
    return axiosHttp({
        method: "get",
        url: `/shop/serviceStore/getHomeInfoById/${params}`
    })
}



// 用户查询商品列表



export function productproductUserList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/shop/productInfo/list",
        data: params,
    })
}


// 店铺资质




export function getQualificationsListById(params:any) {
    return axiosHttp({
        method: "get",
        url: `/shop/enterpriseMessage/getQualificationsListById/${params}`,

    })
}

// 新增产品


export function productproductInfo(params:any) {
    return axiosHttp({
        method: "post",

        url: "/shop/productInfo/add",
        data: params,
    })
}

// 修改产品



export function productproductUpdate(params:any) {
    return axiosHttp({
        method: "post",

        url: "/shop/productInfo/update",
        data: params,
    })
}

//更改产品状态


export function productInfoUsed(params:any) {
    return axiosHttp({
        method: "get",
        url: `/shop/productInfo/is/used?${objToSearchParams(params)}`,
    })
}


// 根据Id获取产品信息

export function productInfoId(id:any) {
    return axiosHttp({
        method: "get",
        url: `/shop/productInfo/enterprise/${id}`,
    })
}

// 删除产品

export function productInfoDel(params:any) {
    return axiosHttp({
        method: "post",
        url: `/shop/productInfo/deleteByProductId/${params.productId}`,
        data:params
    })
}

// 获取产品评价




export function getProductReviews(id:any,params:any) {
    return axiosHttp({
        method: "get",
        url: `/shop/productReviews/getInfo/${id}`,
        data:params
    })
}
// 获取企业认证信息



export function enterpriseMessageGetByUser(params:any) {
    return axiosHttp({
        method: "get",
        url: `/shop/enterpriseMessage/getByUser/${params}`
    })
}

// 修改认证消息


export function enterpriseMessageaddOrUpdate(params:any) {
    return axiosHttp({
        method: "post",
        url: "/system/conAuth/update",
        data: params,
    })
}


// 店铺管理

// 获取店铺信息




export function getserviceStore(params:any) {
    return axiosHttp({
        method: "get",
        url: "/shop/serviceStore/getByUser",
        data: params,
    })
}

// 修改店铺信息

export function serviceStoreUpdateBybo(params:any) {
    return axiosHttp({
        method: "post",
        url: `/shop/serviceStore/updateBybo`,
        data: params,
    })
}


// 服务商管理



export function serviceAgentlist(params:any) {
    return axiosHttp({
        method: "get",
        url: "/shop/serviceAgent/listByUserId",
        data: params,
    })
}


// 新增代理人


export function serviceAgentAdd(params:any) {
    return axiosHttp({
        method: "post",
        url: "/shop/serviceAgent/add",
        data: params,
    })
}


// 删除代理人





export function serviceAgentdelete(id:any) {
    return axiosHttp({
        method: "post",
        url: `/shop/serviceAgent/delete/${id}`,
    })
}


// 修改代理人



export function serviceAgentedit(params:any) {
    return axiosHttp({
        method: "post",
        url: "/shop/serviceAgent/edit",
        data: params,
    })
}


// 用户收藏店铺



export function collectShop(params:any) {
    return axiosHttp({
        method: "post",
        url: `/shop/collectShop/add/${params.storeId}`,
        data: params,
    })
}
// 取消收藏
export function collectShopDel(params:any) {
    return axiosHttp({
        method: "post",
        url: `/shop/collectShop/${params}`,
    })
}

// 根据店铺查询收藏信息
export function collectShopGet(params:any) {
    return axiosHttp({
        method: "get",
        url: `/shop/collectShop/getByUserId`,
        data:params
    })
}

// 收藏店铺；列表



export function collectShopList(params:any) {
    return axiosHttp({
        method: "get",
        url: `/shop/collectShop/list`,
        data: params,
    })
}

// 用户收藏产品



export function collectProduct(params:any) {
    return axiosHttp({
        method: "post",
        url: `/shop/collectProduct/add/${params.productId}`,
        data: params,
    })
}
// 用户取消收藏产品
export function collectProductDel(params:any) {
    return axiosHttp({
        method: "post",
        url: `/shop/collectProduct/${params}`,
    })
}
// 判断用户是否收藏商品
export function collectProductgetByUserId(params:any) {
    return axiosHttp({
        method: "get",
        url: `/shop/collectProduct/getByUserId`,
        data:params
    })
}

// 用户收藏产品列表


export function collectProductList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/shop/collectProduct/list",
        data: params,
    })
}

// 帮助与反馈


export function providerFeedback(params:any) {
    return axiosHttp({
        method: "get",
        url: "/shop/providerFeedback/list",
        data: params,
    })
}


// 获取城市


export function getDistrict(params:any) {
    return axiosHttp({
        method: "get",
        url: "/shop/serviceStore/getDistrict",
        data: params,
    })
}
