import {axiosHttp} from "../ajaxData"



// 获取用户信息

export function getUserInfo() {
    return axiosHttp({
        method: "get",
        url: "/system/user/getInfo",
    })
}


// 获取企业信息


export function enterpriseMessageInfo(params:any) {
    return axiosHttp({
        method: "get",
        url: `/shop/enterpriseMessage/getByUser/${params}`,
    })
}

// 修改个人信息

export function getUserEditInfo(params:any) {
    return axiosHttp({
        method: "post",
        url: "/system/user/profile/update",
        data: params,
    })
}


// 新增或修改个人信息

export function enterpriseupdateAuth(params:any) {
    return axiosHttp({
        method: "post",
        url: "/system/user/profile/updateAuth",
        data: params,
    })
}
// 新增或修改企业信息


export function enterpriseMessageaddOrUpdate(params:any) {
    return axiosHttp({
        method: "post",
        url: "/shop/enterpriseMessage/addOrUpdate",
        data: params,
    })
}

// 企业认证


export function conAuthAdd(params:any) {
    return axiosHttp({
        method: "post",
        url: "/system/conAuth/add",
        data: params,
    })
}

// 修改企业认证




export function updateOldAuth(params:any) {
    return axiosHttp({
        method: "post",
        url: "/system/conAuth/updateOldAuth",
        data: params,
    })
}
// 查询企业认证状态



export function queryByType(params:any) {
    return axiosHttp({
        method: "get",
        url: "/system/conAuth/queryByType",
        data: params,
    })
}

// 忘记密码

export function restPwd(params:any) {
    return axiosHttp({
        method: "post",
        url: "/system/user/profile/restPwd",
        data: params,
    })
}

// 修改密码
export function updatePassWord(params:any) {
    return axiosHttp({
        method: "post",
        url: "/system/user/profile/updatePassWord",
        data: params,
    })
}


// 订单发票列表
export function ownPageList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/order/orderInvoice/ownPageList",
        data: params,
    })
}

// 开票

export function makeInvoice(params:any) {
    return axiosHttp({
        method: "post",
        url: `/order/orderInvoice/makeInvoice/${params.invoiceId }`,
        data: params,
    })
}

// 查询发票列表


export function invoiceManageList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/system/invoiceManage/list",
        data: params,
    })
}


// 新增发票
export function invoiceManageinsert(params:any) {
    return axiosHttp({
        method: "post",
        url: "/system/invoiceManage/insert",
        data: params,
    })
}
// 更新发票信息
export function invoiceManageupdate(params:any) {
    return axiosHttp({
        method: "post",
        url: "/system/invoiceManage/update",
        data: params,
    })
}
// 删除发票
export function invoiceManageRemove(params:any) {
    return axiosHttp({
        method: "post",
        url: `/system/invoiceManage/remove?id=${params.id}`,
        data: params,
    })
}


// 获取平台公告



export function platformNoticeList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/system/platformNotice/list",
        data: params,
    })
}

// 获取平台协议
export function platformAgreementList(params:any) {
    return axiosHttp({
        method: "get",
        url: "/system/platformAgreement/list",
        data: params,
    })
}

// 获取平台信息


export function getByType(params:any) {
    return axiosHttp({
        method: "get",
        url: `/system/platformContent/getByType/${params}`,

    })
}

// 获取问题列表

export function platformProblemList(params:any) {
    return axiosHttp({
        method: "get",
        url: `/system/platformProblem/list`,
        data: params,
    })
}

// 获取评价列表


export function getproductReviews(params:any) {
    return axiosHttp({
        method: "get",
        url: `/shop/productReviews/getInfo/${params.productId}`,
data:params
    })
}

// 回复产品评价



export function productReviewsReplys(params:any) {
    return axiosHttp({
        method: "post",
        url: `/shop/productReviews/reply`,
        data:params
    })
}


// 修改手机号



export function profileupdateTel(params:any) {
    return axiosHttp({
        method: "post",
        url: `/system/user/profile/updateTel`,
        data:params
    })
}

// 修改邮箱



export function updateEmail(params:any) {
    return axiosHttp({
        method: "post",
        url: `/system/user/profile/updateEmail`,
        data:params
    })
}
// 拓客列表


export function broadList(params:any) {
    return axiosHttp({
        method: "get",
        url: `/system/user/broadList`,
        data: params,
    })
}

// 获取保密协议

export function agreementType(params:any) {
    return axiosHttp({
        method: "get",
        url: `/system/platformAgreement/getInfoByType`,
        data: params,
    })
}
