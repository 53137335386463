<template>
    <div class="contents">
        <span class="title">邀请规则</span>
        <div class="subtitle">
            专利申请的好处，为什么要申请专利？<br>
            1） 防止专利被窃取反被告侵权，保护自己的科研成果； （仅有科技成果是不行的，企业不可能拿科技成果打击对手！保护自己，防止被别人模仿，只有专利才有这个本事啊。）<br>
            2） 独占市场，保护自己的市场垄断地位；<br>
            3） 专利是可以赚！钱！的！（转让、继承、财产投资、质押等）（专利是一种无形资产，可以转让、继承、财产投资、质押等，也可以许可给别人使用，收取使用许可费。）专利转让或授权获利；<br>
            4） 借助专利寻求合作机会；<br>
            5） 提升形象，品牌效应，防止人才流失；<br>
            6） 政策沾光，申请补助；<br>
            7） 高企认证有要求；<br>
            8） 成为大户有奖励；<br>
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
    .contents {
        width: 381px;
        background: #FFFFFF;
        height: 100%;
        box-shadow: 0px 1px 3px 1px rgba(55, 82, 133, 0.2);
        padding: 30px;

        .title {
            font-size: 20px;
            font-weight: bold;
            color: #121212;
        }

        .subtitle {
            font-size: 12px;
            margin-top: 20px;
            font-weight: 400;
            color: #121212;
            line-height: 24px;
        }
    }
</style>
