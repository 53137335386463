<template>
    <div>
        <a-form layout="inline">
            <a-form-item>
                <a-input allowClear v-model:value="fromList.orderCode" placeholder="订单号"></a-input>
            </a-form-item>
            <a-form-item>
                <a-input allowClear v-model:value="fromList.shopName" placeholder="服务商"></a-input>
            </a-form-item>
            <a-form-item>
                <a-input allowClear v-model:value="fromList.productName" placeholder="产品名称"></a-input>
            </a-form-item>
             <a-form-item>
                 <el-date-picker
                         v-model="time"
                         type="daterange"
                         unlink-panels
                         range-separator="~"
                         start-placeholder="开始时间"
                         end-placeholder="结束时间"
                         @change="changeTime"
                 />
             </a-form-item>
            <a-form-item>
                <a-button @click="handleSearch">查询</a-button>
                <a-button @click="handleRest">重置</a-button>
            </a-form-item>
        </a-form>
    </div>
</template>

<script setup>
    import {reactive, ref, toRefs} from "vue";
    import useCurrentInstance from "@/hooks/useCurrentInstance";
    import {getStorage} from "@/storage";
    import {message} from 'ant-design-vue'

    const {proxy} = useCurrentInstance()

    const fromList = ref({

        productName: '',
        shopName: '',
        createTimeStart: '',
        createTimeEnd: '',
        orderCode: '',
        pageSize: 10,
        pageNum: 1,
        type:3
    })
    const time = ref(null)
    const fileList = ref([])
    const handleRest = () => {
        fromList.value = {
            productName: '',
            shopName: '',
            createTimeStart: '',
            createTimeEnd: '',
            orderCode: '',
            pageSize: 10,
            pageNum: 1,
            type:3
        }

        $emit('handleRest', fromList.value)
    }
    const handleSearch = () => {
        time.value = null
        $emit('handleRest', fromList.value)
    }
    const handleAdd = () => {
        $emit('handleAdd')
    }
    const changeTime = (e) => {
        fromList.value.orderTimeStart = e[0]
        fromList.value.orderTimeEnd = e[1]

    }
    const $emit = defineEmits(['handleRest'])
</script>

<style scoped lang="scss">
    button {
        width: 100px;
        margin-right: 20px;
    }
</style>
