// 服务商菜单

let orderDetail = 'serviceManagement/orderManagement/lookDetail/orderDetail' //订单列表

let agentManagement = 'serviceManagement/merchantManagement/agentManagement/agentManagement' //代理人管理
let Information = 'serviceManagement/merchantManagement/Information/Information' //企业信息
let storeManagement = 'serviceManagement/merchantManagement/storeManagement/storeManagement' //门店管理
let productList = 'serviceManagement/productManagement/productList/productList' //产品列表
let productEvaluate = 'serviceManagement/productManagement/evaluate/evaluate' //产品列表
let patentRequirementsManagement = 'serviceManagement/preSalePatentManagement/patentRequirementsManagement/patentRequirementsManagement' //售前专利管理
let patentMining = 'serviceManagement/preSalePatentManagement/patentMining/patentMining' //售前专利管理
let addPatent = 'serviceManagement/preSalePatentManagement/addPatent/addPatent' //售前专利管理
let patentList = 'serviceManagement/patentManagement/patentList/patentList' //订单列表
let patentDetail = 'serviceManagement/patentManagement/patentDetail/patentDetail' //订单列表
let submitInformation = 'serviceManagement/orderManagement/submitInformation/submitInformation'
let contractList = 'serviceManagement/contractManagement/contractList/contractList'
let orderList = 'serviceManagement/orderManagement/orderList/orderList' //订单列表
let changePassword = 'serviceManagement/systemSettings/changePassword/changePassword'
let helpAndFeedback = 'serviceManagement/systemSettings/helpAndFeedback/helpAndFeedback'
let aboutUs = 'serviceManagement/systemSettings/aboutUs/aboutUs'
let feeMonitoring = 'serviceManagement/systemSettings/annualFeeMonitoring/annualFeeMonitoring'
let account = 'serviceManagement/walletManagement/account/account'
let transactionFlow = 'serviceManagement/walletManagement/transactionFlow/transactionFlow'
let personalInformation = 'personalInformation/personalInformation' //个人信息
let message = 'message/message'
const ServicRoutes = [
    {
        path: '/serviceManagement',
        name: '服务端商家管理',
        // redirect: "/serviceManagement/Information",
        meta: {
            icon: 'icon-shangjia',
            title: '商家管理',
            isFull: false,
        },
        children: [
            {
                path: '/serviceManagement/Information',
                name: '服务端企业信息',
                component: Information,

                meta: {
                    icon: '',
                    title: '企业信息',
                },
            },
            {
                path: '/serviceManagement/storeManagement',
                name: '服务端店铺管理',
                component: storeManagement,

                meta: {
                    icon: '',
                    title: '店铺管理',
                },
            },
            {
                path: '/serviceManagement/agentManagement',
                name: '服务端代理人管理',
                component: agentManagement,

                meta: {
                    icon: '',
                    title: '代理人管理',
                },
            },
        ],
    },
    {
        path: '/productManagement',
        name: '服务端产品管理',
        redirect: "/productManagement/productList",
        meta: {
            icon: 'icon-chanpin',
            isFull: false,
            title: '产品管理',
        },
        children: [
            {
                path: '/productManagement/productList',
                name: '服务端产品列表',
                component: productList,

                meta: {
                    icon: '',
                    title: '产品列表',

                },
            }, {
                path: '/productManagement/productEvaluate',
                name: '服务端产品评价',
                component: productEvaluate,

                meta: {
                    icon: '',
                    title: '产品评价',
                    isHide: true
                },
            },
        ],
    },
    {
        path: '/preSalePatentManagement',
        name: '服务端售前专利管理',
        redirect: "/preSalePatentManagement/patentRequirementsManagement",
        meta: {
            icon: 'icon-yangkuan',
            isFull: false,
            title: '售前专利管理',
        },
        children: [
            {
                path: '/preSalePatentManagement/patentRequirementsManagement',
                name: '服务端专利需求',
                component: patentRequirementsManagement,

                meta: {
                    icon: '',
                    title: '专利需求',
                },
            },
            {
                path: '/preSalePatentManagement/patentMining',
                name: '服务端专利挖掘',
                component: patentMining,

                meta: {
                    icon: '',
                    isHide: true,
                    title: '专利挖掘',
                },
            },
            {
                path: '/preSalePatentManagement/addPatent',
                name: '服务端专利新增',
                component: addPatent,

                meta: {
                    icon: '',
                    isHide: true,
                    title: '专利新增',
                },
            },
        ],
    },
    {
        path: '/patentManagement',
        name: '服务端专利管理',
        redirect: "/patentManagement/patentList",
        meta: {
            icon: 'icon-zhuanli',
            isFull: false,
            title: '专利管理',
        },
        children: [
            {
                path: '/patentManagement/patentList',
                name: '服务端专利列表',
                component: patentList,

                meta: {
                    icon: '',
                    title: '专利列表',
                },
            },
            {
                path: '/patentManagement/patentDetail',
                name: '服务端专利详情',
                component: patentDetail,

                meta: {
                    icon: '',
                    isHide: true,
                    title: '专利详情',
                },
            },
        ],
    },
    {
        path: '/orderManagement',
        name: '服务端订单管理',
        redirect: "/orderManagement/orderList",
        meta: {
            icon: 'icon-dingdan',
            title: '订单管理',
            isFull: false,
        },
        children: [
            {
                path: '/orderManagement/orderList',
                name: '服务端订单列表',
                component: orderList,

                meta: {
                    icon: '',
                    title: '订单列表',
                },
            },
            {
                path: '/orderManagement/orderDetail',
                name: '服务端订单详情',
                component: orderDetail,

                meta: {
                    icon: '',
                    title: '订单详情',
                    isHide: true
                },
            },
            {
                path: '/orderManagement/submitInformation',
                name: '服务端提交资料',
                component: submitInformation,

                meta: {
                    icon: '',
                    title: '提交资料',
                    isHide: true
                },
            },
        ],
    },
    {
        path: '/contractManagement',
        name: '服务端合同管理',
        redirect: "/contractManagement/contractList",
        meta: {
            icon: 'icon-dingdan',
            title: '合同管理',
            isFull: false,
        },
        children: [
            {
                path: '/contractManagement/contractList',
                name: '服务端合同列表',
                component: contractList,

                meta: {
                    icon: '',
                    title: '合同列表',
                },
            },
        ],
    },
    {
        path: '/serverMessage',
        name: '服务端消息中心',
        redirect: "/message",
        meta: {
            icon: 'icon-dingdan',
            title: '消息管理',
            isFull: false,
        },
        children: [
            {
                path: '/message',
                name: '服务端消息中心',
                component: message,
                meta: {
                    icon: 'icon-a-zu13817',
                    title: '消息列表',
                },
            },
        ],
    },
    {
        path: '/systemSettings',
        name: '服务端系统设置',
        redirect: "/systemSettings/changePassword",
        meta: {
            icon: 'icon-shezhi',
            title: '系统设置',
            isFull: false,
        },
        children: [
            {
                path: '/systemSettings/changePassword',
                name: '服务端修改密码',
                component: changePassword,

                meta: {
                    icon: '',
                    title: '修改密码',
                },
            },
            {
                path: '/systemSettings/helpAndFeedback',
                name: '服务端帮助与反馈',
                component: helpAndFeedback,

                meta: {
                    icon: '',
                    title: '帮助与反馈',
                },
            },
            {
                path: '/systemSettings/aboutUs',
                name: '服务端关于我们',
                component: aboutUs,

                meta: {
                    icon: '',
                    title: '关于我们',
                    isHide: true
                },
            },
            {
                path: '/systemSettings/feeMonitoring',
                name: '服务端年费监控',
                component: feeMonitoring,

                meta: {
                    icon: '',
                    title: '年费监控',
                },
            },
        ],

    },
    {
        path: '/walletManagement',
        name: '服务端钱包管理',
        isFull: false,
        meta: {
            icon: 'icon-a-zu13815',
            title: '钱包管理',
        },
        children: [
            {
                path: '/account',
                name: '服务端账户管理',
                component: account,
                meta: {
                    icon: '',
                    title: '账户管理',
                },
            },
            {
                path: '/transactionFlow',
                name: '服务端交易流水',
                component: transactionFlow,
                meta: {
                    icon: '',
                    title: '交易流水',
                },
            },
        ],
    }
]

export default ServicRoutes
